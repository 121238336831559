<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <form @submit.prevent="findOrder">
            <div class="card">
              <div class="card-body">
                <p>&nbsp;</p>
                <div class="media">
                  <vue-feather class="m-r-30" type="search"></vue-feather>
                  <div class="media-body">
                    <h4 class="f-w-500"> {{this.currentLanguage ==='en'?"Find participance พนมรุ้ง มินิมาราธอน 2024 แชริตี้รัน  ":"ค้นหาผู้เข้าร่วมกิจกรรม พนมรุ้ง มินิมาราธอน 2024 แชริตี้รัน  "}}</h4>
                    <div class="row">
                      <div class="mb-3 col-sm-6">
                        <label for="moblieNo"
                          ><b>
                            {{this.currentLanguage ==='en'?"Search by":"คำค้นหา"}}
                            
                          </b>
                          
                        </label>
                        <input
                          
                          maxlength="50"
                          minlength="3"
                          type="text"
                          class="form-control"
                          id="moblieNo"
                          placeholder="ชื่อ, หมายเลขบัตรประชาชน , หมายเลขโทรศัพท์, ชมรม"
                          v-model="mobile"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> {{this.currentLanguage ==='en'?"Search":"ค้นหา"}}
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      {{this.currentLanguage ==='en'?"Cancel":"ยกเลิก"}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="row" v-if="this.start_search === 'true'">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="card">
            <div class="card-body">
              <div v-if="this.is_searching === 'true'">
                <div class="d-flex justify-content-center">
                  <img width="60" src="../../assets/images/running_loading.svg" />
                </div>
              </div>

              <div
                v-if="!this.resultList.length && this.is_searching === 'false'"
              >
                <table class="table table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Name":"ชื่อ-นามสกุล"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Type":"ประเภท"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"DOB":"วันเกิด"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"club":"ชมรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"ID Card":"เลขบัตรประชาชน"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"mobile no":"หมายเลขโทรศัพท์"}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" style="text-align: center">
                        {{this.currentLanguage ==='en'?"order not found":"ไม่พบข้อมูล"}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="this.resultList.length" class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?"Status":"สถานะ"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Name":"ชื่อ-นามสกุล"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"Type":"ประเภท"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"DOB":"วันเกิด"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"club":"ชมรม"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"ID Card":"เลขบัตรประชาชน"}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?"mobile no":"หมายเลขโทรศัพท์"}}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(src, index) in this.resultList" :key="index">
                      <td>{{ src.status }}</td>
                      <td>{{ src.name }} </td>
                      <td>{{ src.type }} </td>
                      <td>{{ src.dob }} </td>
                      <td>{{ src.club }} </td>
                      <td>{{ src.id_card }} </td>
                      <td>{{ src.mobile_no }} </td>                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'
export default {
  data() {
    return {
      email: "",
      mobile: "",
      resultList: {},
      status: "",
      is_searching: "false",
      start_search: "false",
      currentLanguage: 'th'
    };
  },
  setup() {
    useHead({
      title: computed(() => `ค้นหาผู้เข้าร่วมกิจกรรม ISHOOTRUN.IN.TH | ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ`),
      meta: [
        {
          name: `description`,
          content: computed(() => `ค้นหาผู้เข้าร่วมกิจกรรม ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ค้นหารูปวิ่ง ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `ค้นหาผู้เข้าร่วมกิจกรรม,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,ishootrun,ishootrunhub,facesearch`),
        }
        ],
     
    })
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async findOrder() {

      if(this.mobile === '' && this.email === ''){
        return
      }
      
      this.start_search = "true";
      this.resultList = {};
      this.is_searching = "true";

      
      const data = {
        txt_str: this.mobile,
      };
      let result = await axios.post("/v1/participance/find", data);
      this.status = result.data.status;
      if (result.data.status === "success") {
        this.resultList = result.data.body;
      }
      this.is_searching = "false";
    },
    async clear_result() {
      this.start_search = "false";
      this.resultList = {};
      this.mobile = "";
      this.email = "";
    },
  },
};
</script>