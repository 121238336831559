<template>
  <Breadcrumbs title="" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" v-if="this.eventDetails.title" > 
            <div class="d-flex justify-content-center">
            <figure class="col-xl-6 col-sm-12" >
                <img :src="this.eventDetails.coverurl" style="opacity: 1;" alt="Image description" class="img-thumbnail" />
            </figure>
          </div>
            <h3>{{ this.eventDetails.title }}</h3>
            <p>
              <b>วันที่จัดกิจกรรม: {{ this.eventDetails.event_date_text }} </b>
              <br>
              <b>ณ: {{ this.eventDetails.province }} </b>
              <br>
              <b>วันที่เปิดให้ชมภาพ: {{ this.eventDetails.on_sale_start }}</b>
            </p>
            <p>
              <span
                v-for="(_package, index) in this.eventPackage"
                :key="index"
                class="btn btn-outline-info" ><i class="fa fa-tag fa-lg" aria-hidden="true" ></i><b>{{ _package.name }}</b>
              </span>
            </p>
            
            <div class="row">
            <!-- <div class="col-sm-1"></div> -->
            <div class="col-sm-12" >
              <label 
                for="fileInput"
                class="drop-container"
                id="dropcontainer"
                @change="faceSearch"
                @click="cart_store.clear_cart"
              >
                <span class="drop-title" v-if="isSearching !== 'true'"
                  ><vue-feather
                    type="upload-cloud"
                    class="text-top"
                  ></vue-feather>
                  อัปโหลดภาพใบหน้า</span
                >

                <span class="drop-title" v-if="isSearching === 'true'"
                  ><vue-feather
                    type="loader"
                    animation="spin"
                    class="text-top"
                  ></vue-feather>
                  <!-- <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> -->
                  กำลังค้นหา...</span
                >
                <input
                  class="btn btn-square btn-outline-info btn-lg"
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  required
                />
              </label>
            </div>
            <!-- <div class="col-sm-12" v-if="this.eventDetails.can_sale === 'N'">
              <div class="d-flex justify-content-center"><h4>ระบบจะเปิดให้ค้นหา {{ this.eventDetails.on_sale_start }} </h4></div>
              <div class="d-flex justify-content-center"> <h6>อดใจรอสักครู่ :)</h6></div>
              <div class="d-flex justify-content-center"> <vue-feather type="activity" size="36" animation-speed="slow"></vue-feather></div>
             
            
            </div> -->
            <!-- <div class="col-sm-1"></div> -->
          </div>
          </div>

          <div class="card-header" v-if="!this.eventDetails.title">
            <div class="d-flex justify-content-center">
              <vue-feather type="loader" size="18" animation="spin" ></vue-feather>กำลังโหลดข้อมูลกิจกรรม...
            </div>
            
          </div>
          
        </div>
        <div class="card">
          <div v-if="cart_store.cart_images.length > 0">
            <p></p>
            <button style="width: 100%" v-if="this.eventDetails.is_free_download === 'Y' && this.images_download_all.length>0" type="button" class="btn btn-primary"  @click.prevent="load_images()">ดาวน์โหลดรูปทั้งหมด </button>
            <p style="text-align: center">
              ค้นพบภาพของท่านจำนวน {{ cart_store.cart_images.length }} ใบ
            </p>
            <button
              v-if="cart_store.cart_images.length > 0 && this.eventDetails.is_mhao === 'Y'"
              class="btn btn-lg btn-primary"
              @click="
                cart_store.addToCartALLImage(
                  cart_store.cart_images,
                  this.eventDetails.is_mhao,
                  this.eventDetails.mhao_price
                )
              "
              style="width: 100%"
            >
              {{ this.eventDetails.caption_button_buy_all }}
               ( เฉลี่ย {{ this.imgs_avg_price }} ) ต่อใบ
            </button>
            
            <div id="imageShowing">
              <figure
                v-for="(src, index) in cart_store.cart_images"
                :key="index"
                @click="() => showImg(index)"
              >
                <div class="gallery event">
                  <img
                    loading="lazy"
                    :src="src.thumbnail_file_name"
                    :alt="src.original_file_name"
                  />

                  

                  <button v-if="src.atc=='N' && this.eventDetails.is_free_download === 'Y'"
                    class="btn"
                    @click="this.download(src.preview_file_name+'?openExternalBrowser=1'),addToCart()"
                  > Download</button>


                  <button v-if="src.atc=='N' && this.eventDetails.is_free_download !== 'Y'"
                    class="btn"
                    @click="
                      cart_store.add_cart(
                        src.id,
                        src.original_file_name,
                        src.thumbnail_file_name,
                        src.photographer_id
                      ),
                        addToCart()
                    "
                  >+เพิ่ม</button>

           
                  
                  <button v-if="src.atc=='Y' && this.eventDetails.is_free_download !== 'Y'"
                    class="btn-delete"
                    
                    @click="
                      cart_store.delete_cart(
                        src.id,
                        src.original_file_name,
                        src.thumbnail_file_name,
                        src.photographer_id
                      ),
                        addToCart()
                    "
                  >-ลบ </button>
                </div>
              </figure>
            </div>
          </div>
          <div
            v-if="this.isFoundImage !== 'true'"
            class="d-flex justify-content-center"
          >
            <div class="card-header">
              <h5>ไม่พบรูป, ลองเปลี่ยนรูปที่ใช้ในการค้นหา แล้วลองอีกครั้ง</h5>
            </div>
          </div>
          <vue-easy-lightbox
            pinchDisabled="true"
            scrollDisabled="true"
            zoomDisabled="true"
            rtl="false"
            loop="true"
            rotateDisabled=true
            moveDisabled="true"
            :visible="visible"
            :index="index"
            :imgs="lightBoxImages"
            @hide="handleHide"
          >
          <!-- <template v-slot:close-btn="{  }">
           
          </template> -->

          <template v-slot:toolbar="{  }">
            <!-- <button @click="toolbarMethods.zoomIn">zoom in</button>
            <button @click="toolbarMethods.zoomOut">zoom out</button>
            <button @click="toolbarMethods.rotateLeft">Anticlockwise rotation</button>
            <button @click="toolbarMethods.rotateRight">clockwise rotation</button> -->
          </template>
          </vue-easy-lightbox>
        </div>
      </div>
    </div>
  </div>

  
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
import { ref } from "vue";
import router from "../../router";
import { useCartStore } from "../../store/modules/cart";
import axios from "axios";
const isBtnaddToCartClick = ref(0);
const isBtnshowImgClick = ref(0);

export default {
  setup() {
    const cart_store = useCartStore();
    cart_store.loadFromLocalStorage();
    return { cart_store };
  },

  data() {
    return {
      isSearching: "false",
      isFoundImage: "true",
      eventDetails: {},
      eventPackage: {},
      lightBoxImages: [],
      visible: false,
      index: "",
      cart: [],
      imgs_avg_price: 0,
      images_download_all: []
    };
  },
  async created() {
    let _data = await this.getEventDetail(this.$route.params.event_id);

  
    if(this.lightBoxImages.length <= 0){
      this.lightBoxImages.length = [];
      this.images_download_all = [];
      this.cart_store.cart_images.forEach((item) => {
          this.lightBoxImages.push({src: item.preview_file_name,title: 'IMAGEID:'+this.$route.params.event_id+''+item.photographer_id+''+item.id });
          this.images_download_all.push(item.preview_file_name);
       });
    }
   
  },
  methods: {
   async sleep_only() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, 200)

    });

},
    async load_images(){

      let result = await Swal.fire({
            icon: 'info',
            title: 'กรุณาเปิดอนุญาตป๊อปอัป เมื่อเริ่มดาวน์โหลด,  ระบบจะดาวน์โหลดรูปทั้งหมด อัตโนมัติ ',
            html: "<b><u>แนะนำใช้งานผ่านคอมพิวเตอร์หรือระบบแอนดรอยด์</u></b>,<br> บนระบบ ios ระบบจะเปิดแทบเท่ากับจำนวนรูปเพื่อให้กดค้างเพื่อบันทึก <br>คุณต้องการดาวน์โหลดรูปทั้งหมดใช่หรือไม่?",
            
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#7991a8',
            confirmButtonText: 'เริ่มดาวน์โหลด',
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (result.isConfirmed) { 

              let count = 0
             // console.log("images_dl.length"+this.images_download_all.length)
              while(count < this.images_download_all.length){
                window.open(this.images_download_all[count]+'?openExternalBrowser=1', "_blank");
                this.sleep_only();
                count++;
              }
            }else{
                return false;
            }
        });
            
    },
    async download(url) {
      window.open(url, "_blank");
    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      //console.log("res.data",JSON.stringify(res.data))
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;

        let consent_check = localStorage.getItem(
          "consent_" + this.$route.params.event_id
        );
        if (consent_check !== "true") {
          let result = await this.cart_store.alert_policy_consent();
          if (result) {
            localStorage.setItem("consent_" + this.$route.params.event_id, "true");
          } else {
            router.push({ path: "/" });
          }
        }
        return res.data;
      } else {
        //alert("พบข้อผิดพลาด! ไม่พบ url ของกิจกรรม");
        router.push({ path: "/404" });
      }
    },
    addToCart() {
      isBtnaddToCartClick.value = 1;
    },
    addAllImageToCart() {
      //router.push({ path: "cart" });
    },
    showImg(index) {
      isBtnshowImgClick.value = 1;

      if (isBtnaddToCartClick.value === 0) {
        this.index = index;
        this.visible = true;
      }
      isBtnaddToCartClick.value = 0;
      isBtnshowImgClick.value = 0;
    },
    handleHide() {
      this.visible = false;
      isBtnaddToCartClick.value = 0;
      isBtnshowImgClick.value = 0;
    },

    faceSearch() {
      if (document.getElementById("fileInput").value != "") {
        var file_size = (fileInput.files[0].size/1000000);
        console.log("fileInput size mb:"+file_size)
        if(file_size >5){
          this.cart_store.alert_upload_file_more_than_5mb()
          return false;
        }
        this.lightBoxImages = [];
        this.isSearching = "true";
        this.isFoundImage = "true";
        this.cart_store.clear_cart_images();
        this.imgs_avg_price = 0;
        document.getElementById("fileInput").disabled = true;
        var formdata = new FormData();
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        formdata.append("file", fileInput.files[0], fileInput.files[0].name);
        formdata.append("event_id", this.eventDetails.event_id);
        formdata.append("event_key", this.$route.params.event_id);
        formdata.append("face_threshold", this.eventDetails.face_threshold);
        formdata.append("mhao_price", this.eventDetails.mhao_price);
        formdata.append("max_face_search", this.eventDetails.max_face_search);
        //console.log(this.eventDetails.mhao_price)
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          process.env.VUE_APP_RUNNING_API + "/v1/facesearch",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);

            //console.log("result.status " + JSON.stringify(result));
            if (result.status === "success") {
              if (result.image_length > 0) {
                this.isFoundImage = "true";
                this.cart_store.cart_images = result.images;
                this.imgs_avg_price = result.avg_price;
                this.cart_store.cart_images.forEach((item) => {
                  //this.lightBoxImages.push(item.preview_file_name);
                  this.lightBoxImages.push({src: item.preview_file_name,title: 'IMAGEID:'+this.$route.params.event_id+''+item.photographer_id+''+item.id });
                  this.images_download_all.push(item.preview_file_name);
                });
                this.isSearching = "false";
                document.getElementById("fileInput").disabled = false;
              } else {
                this.isSearching = "false";
                document.getElementById("fileInput").disabled = false;
                //alert("ไม่พบรรูปที่ตรงกัน");
                this.isFoundImage = "false";
              }
            } else {
              this.isSearching = "false";
              this.isFoundImage = "false";
              document.getElementById("fileInput").disabled = false;
              this.cart_store.alert_commomd_message_info(
                "พบข้อผิดพลาดจากการค้นหา กรุณาลองอีกครั้ง"
              );
              //alert("พบข้อผิดพลาดจากการค้นหา กรุณาลองอีกครั้ง");
            }
          })
          .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  display: none;
}

div.gallery:hover {
  cursor: pointer;
  opacity: 0.9;
}

@media screen and (min-width: 451px) and (max-width: 9000px) {
  div.gallery {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
  }

  div.gallery img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  div.gallery {
    width: 49%;
    float: left;
    margin: 1px;
    padding: 2px;
    border: 1px solid #ccc;
  }

  div.gallery img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }
}

.drop-container {
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  border-radius: 30px;
  border: 2px dashed #0486d6;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}
.drop-container{
  background: #99989846;
}
.drop-container:hover {
  background: #979696ad;
}
/* 
.drop-container:hover .drop-title {
  color: #222;
} */

.drop-title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

input[type="file"] {
  display: none;
}

.event {
  position: relative;
  width: auto;
  height: auto;
}

.event img {
  height: auto;
  width: auto;
}

.event .btn {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #0486d6;
  color: white;
  font-size: 13px;
  padding: 5px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.event .btn-delete {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;;
  color: white;
  font-size: 13px;
  padding: 5px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.event .btn-delete-go-to-cart {
  position: absolute;
  top: 10%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;;
  color: white;
  font-size: 13px;
  padding: 5px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}


/*
.event .btn:hover {
  background-color: black;
}
.event .btn-delete:hover {
  background-color: black;
}
*/
</style>
    