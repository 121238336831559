<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-5 box-col-7">
          <div class="card bg-primary">
            <div class="card-body">
              <p>&nbsp;</p>
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>{{this.currentLanguage ==='en'?"About us":"เกี่ยวกับเรา"}}</h2>
                  <p>
                    iSHOOTRUN.IN.TH ระบบจำหน่ายรูปภาพงานวิ่ง, บริการ ค้นหารอยยิ้ม
                  </p>
                  <p>A picture is worth a thousand words</p>
                  <p>&nbsp;</p>
                </div>
                <vue-feather type="award"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-7 col-sm-5 box-col-5">
          <div class="card bg-primary">
            <div class="card-body">
              <p>&nbsp;</p>
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>{{this.currentLanguage ==='en'?"Contact us":"ติดต่อเรา"}}</h2>
                  <!-- <p>172 ถนนกำแหงสงคราม ในเมือง เมืองนครราชสีมา นครราชสีมา 30000 </p> -->
                  <p>LINEID: @ishootrun.in.th</p>
                  <p>page: https://facebook.com/Ishootrunteam</p>
                </div>
                <vue-feather type="mail"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <p>&nbsp;</p>
        <div class="col-xl-7 box-col-7">
          <div class="card bg-primary">
            <div class="card-body">
              <p>&nbsp;</p>
              <div class="media faq-widgets">
                
                <div class="media-body">
                  <h2>{{this.currentLanguage ==='en'?"Mission":"พันธกิจ"}}</h2>
                  <h5>
                    ร่วมพัฒนาไปด้วยกัน #พัฒนาคน #พัฒนางาน #พัฒนาระบบ
                    สู่มาตรฐานเดียวกัน
                  </h5>
                  <b style="text-align: right">We will make perfect for all</b>
                </div>
                <vue-feather type="hash"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 box-col-5">
          <div class="card">
            <div class="card-body">
              <div class="media faq-widgets">
                <img
                  style="align-items: center; width: 440px"
                  src="../../assets/images/logo/logo.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {

  setup() {
    useHead({
      title: computed(() => `เกี่ยวกับเรา ระบบจำหน่ายรูปภาพงานวิ่ง, บริการ ค้นหารอยยิ้ม | ISHOOTRUN.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `ร่วมพัฒนาไปด้วยกัน #พัฒนาคน #พัฒนางาน #พัฒนาระบบ สู่มาตรฐานเดียวกัน ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `เกี่ยวกับเรา,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,ishootrun,ishootrunhub,facesearch`),
        }
        ],
     
    })
  },
  data() {
    return {
      currentLanguage: 'th'   
    };
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },


};
</script>