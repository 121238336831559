<template>
  <Breadcrumbs main="Ecommerce" title="CART____OPN" />
  <div class="container-fluid text-start">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" v-if="Object.keys(cart_store.cart).length <= 0">
          <div class="card-body">
            <div class="card-header">
              <h4>รายการสั่งซื้อ และชำระเงิน</h4>
            </div>
            <div class="row">
              <div class="d-flex justify-content-center">
                <div class="card-header">
                  <vue-feather
                    type="shopping-cart"
                    style="width: 100px; text-align: center"
                  ></vue-feather>
                  <h5>ไม่พบรูปในตะกร้า</h5>
                </div>
              </div>
            </div>
            <div class="row">
              <button class="btn btn-primary" @click="prvback()">
                เริ่มค้นหารูปใหม่
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card">
            <div class="card-body">
              <div class="card-header">
                <h4>รายการสั่งซื้อ และชำระเงิน</h4>
                <p>
                  <b> {{ this.eventDetails.title }} </b>
                </p>
                <p>
                  <span
                    v-for="(_package, index) in this.eventPackage"
                    :key="index"
                    class="btn btn-outline-info"
                    ><b>{{ _package.name }}</b>
                  </span>
                </p>
                <!-- {{ this.cart_store.total }}  -->
              </div>
              <div class="container-fluid">
                <figure v-for="(src, index) in cart_store.cart" :key="index">
                  <div class="gallery gellerycont">
                    <img
                      :src="src.thumbnail_file_name"
                      :alt="src.original_file_name"
                      loading="lazy"
                    />

                    <input type="checkbox" :id="src.id" />
                    <label
                      class="btn"
                      @click="cart_store.removeImageInCart(src.id),this.checkOnceEventId()"
                      :for="src.id"
                      >ลบ</label
                    >
                  </div>
                </figure>
              </div>
            </div>

            <div class="card-body">
              <div class="card-header">
                

                <div class="row">
                  <button class="btn btn-primary" @click="prvback()">
                    กลับไปค้นหารูปใหม่
                  </button>
                  <h3></h3>
                  <button class="btn btn-danger" @click="cart_store.clear_all_cart_in_cart_page">
                    ลบรูปทั้งหมดในตะกร้า 
                  </button>
                  <h3></h3>
                  
                    <button
                      v-if="this.is_mhao_flag_init==='Y' && this.cart_store.is_mhao_flag === 'YES'"
                      class="btn btn-primary"
                      @click.prevent="this.cart_store.clear_mhao(this.eventDetails.is_mhao, this.eventDetails.mhao_price)"
                    >
                      ยกเลิกการเลือกโปรเหมาๆ
                    </button>

                    <button
                      v-if="this.is_mhao_flag_init==='Y' && this.cart_store.is_mhao_flag !== 'YES'"
                      class="btn btn-primary"
                      @click.prevent="
                        this.cart_store.select_pro_mhao(
                          this.eventDetails.is_mhao,
                          this.eventDetails.mhao_price
                        )
                      "
                    >
                      {{ this.eventDetails.caption_button_buy_all }}
                    </button>
               
                </div>
              </div>
              <div
                class="table-responsive"
                style="margin-left: auto; margin-right: 0"
              >
                <table
                  
                  class="table align-to-right"
                  style="width: 80%; text-align: center"
                >
                  <thead>
                    <tr class="border-bottom-light">
                      <th scope="col" style="text-align: right; width: 50%">
                        จำนวนรูป
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ cart_store.cart.length }} ใบ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-bottom-light">
                      <th scope="col" style="text-align: right">ยอดรวม</th>
                      <th scope="col" style="text-align: right">
                       <b><span
                            v-if="this.cart_store.is_mhao_flag === 'YES'"
                            class="badge badge-pill badge-info"
                            ><vue-feather
                              type="paperclip"
                              size="20"
                            ></vue-feather
                            ><b>เลือกโปรเหมาๆ</b>
                          </span> {{ this.cart_store.cal_cart.cart_full_amt }}
                        บาท</b>
                      </th>
                    </tr>
                  </tbody>

                  <!-- <tbody>
                    <tr class="border-bottom-light">
                      <th scope="col" style="text-align: right">
                        ส่วนลด Package
                        <p>
                          <span
                            v-for="(_package, index) in this.eventPackage"
                            :key="index"
                            class="badge badge-pill badge-info"
                            ><vue-feather
                              type="paperclip"
                              size="20"
                            ></vue-feather
                            ><b>{{ _package.name }}</b>
                          </span>
                        </p>
                      </th>
                      <th scope="col" style="text-align: right">
                        - {{ this.cart_store.cal_cart.cart_discount }}
                        บาท
                      </th>
                    </tr>
                  </tbody>
                   
                  <tbody>
                    <tr class="border-bottom-dark">
                      <th scope="col" style="text-align: right">
                        ยอดรวมหลังหักส่วนลด
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ this.cart_store.total }}
                        บาท
                      </th>
                    </tr>
                  </tbody>
                -->
                  <tbody>
                    <tr class="border-bottom-white">
                      <th scope="col" style="text-align: right">
                        ค่าธรรมเนียมการชำระเงิน(5%)
                      </th>
                      <th scope="col" style="text-align: right">
                        {{ this.cart_store.cal_cart.cart_payment_fee }}
                        บาท
                      </th>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="border-bottom-primary">
                      <th scope="col" style="text-align: right">
                        <b>รวมยอดชำระ</b>
                      </th>
                      <th scope="col" style="text-align: right">
                        <b
                          >
                          {{ this.cart_store.cal_cart.cart_final_payment }}
                          บาท</b
                        >
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          

          <div class="row" v-if="this.is_once_event_id ==='N'">
                  <div class="d-flex justify-content-center">
                    <h2 style="color: red;"><u>กรุณาเลือกรูปในตะกร้าที่มาจากกิจกรรมเดียวกัน เท่านั้น</u></h2>
                  </div>
                  <div>&nbsp;</div>
          </div>

          <form @submit.prevent="submitCart" class="needs-validation" v-if="this.is_once_event_id !=='N'">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="media">
                        <vue-feather class="m-r-30" type="info"></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500"></h6>
                          <div class="row">
                            <div class="mb-3 col-sm-6">
                              <label for="moblieNo" class="form-label"
                                ><h3>
                                  หมายเลขโทรศัพท์
                                  <span style="color: red">*</span>
                                </h3>
                                <small class="form-text text-muted"
                                  >ป้อนแค่ตัวเลขไม่ต้องมีขีด, ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อชำระเงินสำเร็จ </small
                                >
                              </label>
                              <input
                                required
                                maxlength="10"
                                minlength="10"
                                type="text"
                                class="form-control"
                                id="moblieNo"
                                placeholder="หมายเลขโทรศัพท์"
                                v-model="this.cart_store.mobileNo"
                              />
                              
                            </div>
                            <div class="mb-3 col-sm-6">
                              <label for="email"
                                ><h3>
                                  อีเมลล์<span style="font-size: small; background: inherit;">(ถ้ามี)</span>
                                </h3>
                                <small class="form-text text-muted"
                                  >หากไม่มีให้เว้นว่าง
                                  </small
                                >
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                placeholder="อีเมลล์(ถ้ามี)"
                                v-model="this.cart_store.email"
                              />
                            </div> 
                            <b><vue-feather type="mail"   size="15"></vue-feather> หากไม่ได้รับ SMS Email หรือลืมคำสั่งซื้อ สามารถค้นหาได้ที่เมนู <a href="/find-order" target="_blank" ><u>"ค้นหาคำสั่งซื้อ"</u></a> ด้านบน</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" >
                  <div class="card">
                    <div class="card-body">
                      <div class="media">
                        <vue-feather
                          class="m-r-30"
                          type="credit-card"
                        ></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500">
                            <h3>
                              ช่องทางการชำระเงิน
                              <span style="color: red">*</span>
                            </h3>
                          </h6>
                          <small class="form-text text-muted"
                            >ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อชำระเงินสำเร็จ, ลิงค์สำหรับดาวน์โหลดภาพจะถูกส่งให้ทาง SMS หรืออีเมลล์ด้วย</small
                          >
                          <p>&nbsp;</p>
                          <div class="form-check">
                            <h6>
                              <input
                                required
                                class="form-check-input"
                                type="radio"
                                v-model="this.cart_store.paymentMethod"
                                value="QR"
                                name="paymentMethod"
                                id="QR"
                              />
                              <label class="form-check-label" for="QR">
                                &NonBreakingSpace; <b>พร้อมเพย์ (PromptPay)</b>
                                <img
                                  style="width: 100px"
                                  src="../../assets/images/running/promptpay-icon.jpg"
                                />
                              </label>
                            </h6>
                          </div>
                          <div class="form-check">
                            <input
                              required
                              class="form-check-input"
                              value="CREDIT"
                              type="radio"
                              v-model="this.cart_store.paymentMethod"
                              name="paymentMethod"
                              id="CREDIT"
                              disabled
                            />
                            <label class="form-check-label" for="CREDIT">
                              &NonBreakingSpace;
                              <b>บัตรเครดิต/เดบิต(Credit Card/debit)</b>
                              <img
                                style="width: 100px"
                                src="../../assets/images/running/credit-card-icon.png"
                              />(ยังไม่พร้อมใช้งานในขณะนี้)
                              <p>
                                &nbsp;&nbsp;<small
                                  style="font-size: x-small"
                                  class="form-text text-muted"
                                  >การชำระเงินผ่านบัตรเครดิตแบบ 3D secure</small
                                >
                              </p>
                            </label>
                          </div>
                          <div class="row">&nbsp;</div>
                          

                          <!-- <div class="row">
                            <div class="d-flex justify-content-center">
                              <div class="checkbox ">
                                <input id="checkbox1" v-model="is_pic_me" type="checkbox"  />
                                <label for="checkbox1">
                                  <h5>
                                    ผู้ซื้อยอมรับว่าเป็นภาพของตนเอง
                                  </h5></label
                                >
                              </div>
                              </div>
                          </div> -->
                          <div class="row" >
                            <div class="d-flex justify-content-center">
                              <!-- <button
                                type="submit" :disabled="is_pic_me===true ? disabled: ''"
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                                จ่ายเงิน  this.is_submit_cart = "Y"
                              </button> -->
                              <button  
                                type="submit" 
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                              <vue-feather type="loader" size="20" animation="spin" v-if="this.is_submit_cart ==='Y'"></vue-feather> จ่ายเงิน
                              </button>
                              &nbsp;
                              <button
                                style="cursor: pointer"
                                type="reset"
                                class="btn btn-secondary btn-lg"
                              >
                                ยกเลิก
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12" >
                  <div class="card">
                    <div class="card-body">
                      <div class="media">
                        <vue-feather
                          class="m-r-30"
                          type="credit-card"
                        ></vue-feather>
                        <div class="media-body">
                          <h6 class="f-w-500">
                            <h3>
                              ช่องทางการชำระเงิน OPN
                              <span style="color: red">*</span>
                            </h3>
                          </h6>
                          <small class="form-text text-muted"
                            >ระบบจะแสดงลิงค์ดาวน์โหลดภาพทันที เมื่อชำระเงินสำเร็จ, ลิงค์สำหรับดาวน์โหลดภาพจะถูกส่งให้ทาง SMS หรืออีเมลล์ด้วย</small
                          >
                          <p>&nbsp;</p>
                          <div class="form-check">
                            <h6>
                              <input
                                required
                                class="form-check-input"
                                type="radio"
                                v-model="this.cart_store.paymentMethod"
                                value="QR"
                                name="paymentMethod"
                                id="QR"
                              />
                              <label class="form-check-label" for="QR">
                                &NonBreakingSpace; <b>พร้อมเพย์ (PromptPay)</b>
                                <img
                                  style="width: 100px"
                                  src="../../assets/images/running/promptpay-icon.jpg"
                                />
                              </label>
                            </h6>
                          </div>
                          <div class="form-check">
                            <input
                              required
                              class="form-check-input"
                              value="CREDIT"
                              type="radio"
                              v-model="this.cart_store.paymentMethod"
                              name="paymentMethod"
                              id="CREDIT"
                              disabled
                            />
                            <label class="form-check-label" for="CREDIT">
                              &NonBreakingSpace;
                              <b>บัตรเครดิต/เดบิต(Credit Card/debit)</b>
                              <img
                                style="width: 100px"
                                src="../../assets/images/running/credit-card-icon.png"
                              />(ยังไม่พร้อมใช้งานในขณะนี้)
                              <p>
                                &nbsp;&nbsp;<small
                                  style="font-size: x-small"
                                  class="form-text text-muted"
                                  >การชำระเงินผ่านบัตรเครดิตแบบ 3D secure</small
                                >
                              </p>
                            </label>
                          </div>
                          <div class="row">&nbsp;</div>
                          

                          <!-- <div class="row">
                            <div class="d-flex justify-content-center">
                              <div class="checkbox ">
                                <input id="checkbox1" v-model="is_pic_me" type="checkbox"  />
                                <label for="checkbox1">
                                  <h5>
                                    ผู้ซื้อยอมรับว่าเป็นภาพของตนเอง
                                  </h5></label
                                >
                              </div>
                              </div>
                          </div> -->
                          <div class="row" >
                            <div class="d-flex justify-content-center">
                              <!-- <button
                                type="submit" :disabled="is_pic_me===true ? disabled: ''"
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                                จ่ายเงิน  this.is_submit_cart = "Y"
                              </button> -->

                              <button type="button" @click="opnPay()" >PAY</button>

                              <button  
                                type="submit" 
                                style="cursor: pointer"
                                class="btn btn-primary btn-lg"
                              >
                              <vue-feather type="loader" size="20" animation="spin" v-if="this.is_submit_cart ==='Y'"></vue-feather> จ่ายเงิน
                              </button>
                              &nbsp;
                              <button
                                style="cursor: pointer"
                                type="reset"
                                class="btn btn-secondary btn-lg"
                              >
                                ยกเลิก
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div style="color: rgba(153, 71, 71, 0)">
            {{ this.cart_store.total }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useCartStore } from "../../store/modules/cart";
import router from "../../router/index";
import axios from "axios";
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  setup() {
    useHead({
      title: computed(() => `รายการสั่งซื้อ และชำระเงิน ระบบจำหน่ายรูปภาพงานวิ่ง, บริการ ค้นหารอยยิ้ม | iSHOOTRUN.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `รายการสั่งซื้อ และชำระเงิน ค้นหารูปวิ่งสุดประทับใจด้วยใบหน้า ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`),
        },
        {
          name: `keywords`,
          content: computed(() => `รายการสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,ishootrun,ishootrunhub,facesearch`),
        }
        ],
     
    })
    const cart_store = useCartStore();
    cart_store.loadFromLocalStorage();
    return { cart_store };
  },
  data() {
    return {
      eventDetails: {},
      eventPackage: {},
      cart_amount: 0,
      is_pic_me: false,
      is_mhao_flag_init: null,
      is_submit_cart: "",
      is_once_event_id: "Y"
      //is_mhao_flag: this.cart_store.is_mhao_flag.value
    };
  },
  async created() {
    if (this.cart_store.cart.length > 0) {
      
      
      let isOnceEvent= this.checkOnceEventId();
      if(isOnceEvent === "Y"){
        this.prepareCart();
      }
    }
  },
  async mounted() {
    //console.log("this.cart_store.total mounted: " + this.cart_store.total);
  },
  computed() {
   // console.log("computed");
    //this.cart_amount = this.cart_store.cart.value.reduce((sum, prd) => sum + 60 * 1, 0)

    //console.log("computed1");
  },
  async beforeMount() {
    //console.log("beforeMount counter: ");
  },
  methods: {
    async opnPay(){
      //alert('opnPay')
      let source = "";
      Omise.setPublicKey("pkey_5ys238svqf6ys7e4syb");
      Omise.createSource('promptpay', {
        "amount": 400000,
        "currency": "THB"
      }, function(statusCode, response) {
          console.log(response)
          source = response.id;
          console.log("sourcein:"+source)



      });

      console.log("source:"+source)
    },
    async insertLogs(image_id, event_key) {
      let data = {
        "service": "cart",
        "cart_count": this.cart_store.cart.length,
        "image_id":image_id,
        "event_key": event_key

      }
      const res = await axios.post("/v1/logs/",data);
      
    },
    async getEventkeyById(image_id) {
      const res = await axios.get("/v1/events/event-key/" + image_id);
      return res.data.event_key;
    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      if (res.data) {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;
        this.is_mhao_flag_init =  this.eventDetails.is_mhao;
        return res.data;
      }
    },
    prvback() {
      router.push("/");
    },
    async submitCart() {
      this.is_submit_cart = "Y"
      const req = {
        mobile: this.cart_store.mobileNo,
        email: this.cart_store.email,
        event_key: this.eventDetails.event_key,
        amount: this.cart_store.cal_cart.cart_amt,
        paid_amount: this.cart_store.cal_cart.cart_final_payment,
        imageList: this.cart_store.cart,
        image_length: this.cart_store.cart.length,
        is_mhao: this.cart_store.is_mhao_flag==='YES'?'Y':'N'
      };

      //console.log("order_creation req",req)
      let order_res = await this.cart_store.order_creation(req);
      //console.log("order_createion_r:", order_res);

      if (order_res.status === "success") {
        //console.log("success: ", order_res);
        
        this.is_submit_cart = "N"
        this.cart_store.clear_all_cart_in_cart_page();
        await this.cart_store.alert_create_order(order_res.uuid);
        
        this.$router.push("/download/" + order_res.uuid);
      } else {
        //console.log("order creation errorx: ", order_res);
        this.cart_store.alert_create_order_failed();
      }
    },
    async checkOnceEventId() {
      let event_id_tmp = "";
      this.is_once_event_id = "Y";
      
      this.cart_store.cart.forEach(img => {
        if(event_id_tmp===""){
          event_id_tmp = img.event_id
        }else{
          if(event_id_tmp !== img.event_id){
            this.is_once_event_id = "N";
          }
        }
      });    
      //console.log("checkOnceEventId:"+ this.is_once_event_id)
      this.prepareCart();
      return this.is_once_event_id;
    },
    async prepareCart() {
      if (this.cart_store.cart.length > 0) {
        const image_id = this.cart_store.cart[0].id;
        let _event_key = await this.getEventkeyById(image_id);
        if (_event_key !== "-") {
        let event_detail = await this.getEventDetail(_event_key);

        this.cart_store.addPackage(event_detail.package);

        if (this.cart_store.is_mhao_flag === "YES") {
          this.cart_store.event_can_mhao = event_detail.is_mhao;
          this.cart_store.mhao_price = event_detail.mhao_price;
          //console.log("this.cart_store.total: " + this.cart_store.total);
        }

        this.insertLogs(image_id,_event_key)
      }
      }
        
    },
  },
};
</script>

<style scoped>
.align-to-right {
  float: right;
  padding: 10px;
  background-color: hotpink;
}

input[type="checkbox"] {
  display: none;
}

div.gellerycont:hover {
  cursor: pointer;
  opacity: 0.9;
}
@media screen and (min-width: 451px) and (max-width: 9000px) {
  div.gellerycont {
    margin: 1px;
    float: left;
    min-height: auto;
    max-height: 193px;
    min-width: auto;
    max-width: 290px;
    
  }

  div.gellerycont img {
    position: relative;
    min-height: 100%;
    max-height: 194.66px;
    min-width: 100%;
    padding: 1px;
    max-width: 290px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  div.gellerycont {
    width: 49%;
    float: left;
    margin: 1px;
    padding: 2px;
    border: 1px solid #ccc;
  }

  div.gellerycont img {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 2px;
  }
}

.gellerycont {
  position: relative;
  width: auto;
  height: auto;
}

.gellerycont img {
  height: auto;
  width: auto;
}

.gellerycont .btn {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #ff1c1c;
  color: white;
  font-size: 13px;
  padding: 5px 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.gellerycont .btn:hover {
  background-color: black;
}
</style>