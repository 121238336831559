<template>
  <div>
    <footer class="footer" :class="this.footer == 'footer-dark' ? 'footer-dark' : ''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">&nbsp; <a href="https://photo.ishootrun.in.th/" target="_blank">{{this.currentLanguage ==='en'?"Photographers":"สำหรับช่างภาพ"}}</a> &nbsp;<a href="/how-to-order">{{this.currentLanguage ==='en'?"How to order":"วิธีการสั่งซื้อ"}}</a> &nbsp; <a href="/find-order">{{this.currentLanguage ==='en'?"Find order":"ค้นหาคำสั่งซื้อ"}}</a>&nbsp; <a href="/"> Copyright 2023 © ishootrun.in.th</a> &nbsp; <a href="/cart">{{this.currentLanguage ==='en'?"Cart":"ตะกร้าสินค้า"}}</a> &nbsp; <a href="/about">{{this.currentLanguage ==='en'?"About us":"เกี่ยวกับเรา"}}</a>&nbsp; <a href="/refund-policy">{{this.currentLanguage ==='en'?"Refund policy":"นโยบายการคืนเงิน"}}</a> &nbsp; <a href="/terms-of-use">{{this.currentLanguage ==='en'?"Terms of use":"นโยบายความเป็นส่วนตัว"}}</a></p>
            <b>WE ARE AI PHOTO SEARCH PROVIDER</b>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footerpage',
  data() {
    return {
      customizer: false,
      currentLanguage: 'th'

    };
  },
  mounted(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>
