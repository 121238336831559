<template>
  <Breadcrumbs title="" main="Home" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              
              <div class="owl-carousel owl-theme" id="owl-carousel-13">
              <swiper autoHeight: true :slides-per-view="1" :space-between="10" :autoplay="autoplay" loop="true" :pagination="{
                clickable: true,
              }" :modules="modules" :breakpoints="swiperOptions.breakpoints">
                  
                  <swiper-slide v-for="(item,index) in this.slider_banner" :key="index">
                    <a :href="item.link_url" target="_blank"> <img :src="item.image_url" alt="" class="img-fluid"></a>
                </swiper-slide>
                  <!-- <swiper-slide >
                     <a href="https://runrace.in.th/vurun2024" target="_blank"> <img  src="https://rnth.s3.ap-southeast-1.amazonaws.com/assest/website/swiper-bar/silder_1.jpg" alt="" class="img-fluid"></a>
                  </swiper-slide>
                  <swiper-slide>
                      <img src="https://rnth.s3.ap-southeast-1.amazonaws.com/assest/website/swiper-bar/silder_2.jpg" alt="" class="img-fluid">
                  </swiper-slide>
                  <swiper-slide>
                      <img src="https://rnth.s3.ap-southeast-1.amazonaws.com/assest/website/swiper-bar/silder_3.jpg" alt="" class="img-fluid">
                  </swiper-slide> -->
              </swiper>
            </div> 
          </div>
          <hr>
            <h3>A picture is worth a thousand words</h3>
            <p>ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ</p>
          </div>

          <div class="card-body" itemscope="">
            <div v-if="loading" class="d-flex justify-content-center">
              <img
                style="align-items: center; width: 50px"
                src="../../assets/images/running_loading.svg"
              />
            </div>
            <div
              class="my-gallery card-body row gallery-with-description"
              itemscope=""
              v-else
            >
              <figure
                class="col-xl-4 col-sm-6"
                v-for="(src, index) in this.events_on_sale"
                :key="index"
              >
                <a v-bind:href="src.event_key">
                  <img style="border-top-left-radius: 0.6em;border-top-right-radius: 0.6em;border: 0px;filter: drop-shadow(0 -1mm 4mm rgb(0, 0, 0,20%));"
                    :src="src.coverurl"
                    alt="Image description"
                    class="img-thumbnail"
                  />
                  <div class="caption" style="box-shadow: 0 0em 1em rgb(0 0 0 / 50%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;">
                    <h4>{{ src.title }}</h4>
                    <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{ src.event_date_text }}</b>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{ src.eventdate }} {{ src.province }}</p>
                  </div>
                </a>
              </figure>
              <paginate
                v-if="this.total_event_onsale > this.init_page_size"
                v-model="page"
                :page-count="this.page_tab"
                :page-range="3"
                :margin-pages="4"
                :clickHandler="clickCallback"
                :prev-text="'<'"
                :next-text="'>'"
                :container-class="'pagination justify-content-center pagination-sm'"
                :page-class="'page-item'"
              >
              </paginate>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>&nbsp;</p>
    <div class="row" v-if="this.events_soon.length > 0">
      <div class="col-sm-12">
        <div class="card-header">
          <h3>{{this.currentLanguage ==='en'?"Upcoming events":"กิจกรรมที่กำลังจะมาถึง"}}</h3>
        </div>
        <div class="card">
          <p>&nbsp;</p>
          <div v-if="loading" class="d-flex justify-content-center">
            <img
              style="align-items: center; width: 50px"
              src="../../assets/images/running_loading.svg"
            />
          </div>
          <div
            v-else
            class="my-gallery card-body row gallery-with-description"
            itemscope=""
          >
            <figure
              class="col-xl-4 col-sm-6"
              v-for="(src, index) in this.events_soon"
              :key="index"
            >
              <a v-bind:href="src.event_key">
                <img style="border-top-left-radius: 0.6em;border-top-right-radius: 0.6em;border: 0px;filter: drop-shadow(0 -1mm 4mm rgb(0, 0, 0,20%));"
                  :src="src.coverurl"
                  alt="Image description"
                  class="img-thumbnail"
                />
                <div class="caption" style="box-shadow: 0em 0em 1em rgb(0 0 0 / 20%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;">
                  <h4>{{ src.title }}</h4>
                  <b>{{ src.event_date_text }}</b>
                  <p>{{ src.eventdate }} {{ src.province }}</p>
                </div>
              </a>
            </figure>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-12">
          <div class="card-header">
            <h3>พาร์ทเนอร์ ของเรา</h3>
          </div>
          <div class="card">
           
              <div v-if="loading" class="d-flex justify-content-center">
                <img
                  style="align-items: center; width: 150px"
                  src="../../assets/images/loading.gif"
                />
              </div>
              <div
            v-else
            class="my-gallery card-body row gallery-with-description"
            itemscope=""
          >
          <figure class="col-xl-4 col-sm-6"   >
                <img
                  src="../../assets/images/running/partners.webp"
                  alt="partners.jpg"
                  class="img-thumbnail"
                />
            </figure>
            <figure class="col-xl-4 col-sm-6"   >
                <img
                  src="../../assets/images/running/partners.webp"
                  alt="partners.jpg"
                  class="img-thumbnail"
                />
                  
             
            </figure>
            <figure class="col-xl-4 col-sm-6"   >
                <img
                  src="../../assets/images/running/partners.webp"
                  alt="partners.jpg"
                  class="img-thumbnail"
                />
               
            </figure>
          </div>
          </div>
      </div>
    </div> -->
  </div>
</template>
    
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

import { useCartStore } from "../../store/modules/cart";
import axios from "axios";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import VuejsPaginateNext from "../../components/Paginate.vue";
export default {
  components: {
    paginate: VuejsPaginateNext,Swiper, SwiperSlide,
  },
  setup() {
    const siteData = reactive({
      title: `ISHOOTRUN.IN.TH | ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ`,
      description: `ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ค้นหารูปวิ่ง ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที`,
      keywords: `ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,ishootrun,ishootrunhub,facesearch`,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
      ],
    });

    const cart_store = useCartStore();
    cart_store.loadFromLocalStorage();
    return { cart_store,
      autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            swiperOptions: {
               //breakpoints: { slidesPerView: 1 } ,
               loop: true
            },
     };
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      events_on_sale: [],
      events_soon: [],
      loading: true,
      page: 1,
      init_page_size: 18,
      page_tab: 0,
      total_event_onsale: 0,
      currentLanguage: 'th',
      slider_banner:[]
    };
  },
  async created() {
    this.currentLanguage = localStorage.getItem('currentLanguage');
    let result = await this.getEvents();
    if (result.status === "success") {
      this.events_on_sale = result.body;
      this.loading = false;
      this.getEventsPage();
      //this.getEventsSoon();
    } else {
      this.loading = false;
      alert("พบข้อผิดพลาด ลองใม่อีกครั้ง");
    }
  },
  async mounted(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
    let result = await this.getEvents();
    if (result.status === "success") {
      this.events_on_sale = result.body;
      this.loading = false;
      this.getSliderBanner();
      this.getEventsPage();
      this.getEventsSoon();
    } else {
      this.loading = false;
      alert("พบข้อผิดพลาด ลองใม่อีกครั้ง");
    }
  },
  methods: {
    async getEvents() {
      let data = {
        page: 1,
        page_size: this.init_page_size,
      };
      const res = await axios.post("/v1/events/pagination", data);
      return res.data;
    },
    async getSliderBanner() {
      const res = await axios.get("/v1/events/slider-banner");
      console.log(res.data)
      this.slider_banner = res.data.body;
    },
    async getEventsPage() {
      let data = {
        event_status: "ON_SALE",
      };
      const pagination_count = await axios.post(
        "/v1/events/pagination-count",
        data
      );
      this.total_event_onsale = pagination_count.data[0].event_count;
      this.page_tab = Math.round(
        this.total_event_onsale / this.init_page_size + 0.5
      );
    },
    async clickCallback(pageNum) {
      let data = {
        page: pageNum,
        page_size: this.init_page_size,
        total_event_onsale: this.total_event_onsale,
        page_tab: this.page_tab,
      };
      const response = await axios.post("/v1/events/pagination", data);

      this.events_on_sale = response.data.body;
    },
    async getEventsSoon() {
      const res = await axios.get("/v1/events/soon");
      this.events_soon = res.data;
    },
  },
};
</script>


    