export var menuItems={
  "data": [
    {
      "headTitle1": "ishootrun.IN.TH",
      "headTitle2": "ishootrun.in.th",
      "type": "headtitle"
    },
    
    
    {
      "path": "/",
      "title": "กิจกรรม",
      "title_en":"Event",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "type": "link",
      "active":false
    },
    {
      "path": "/how-to-order",
      "title": "วิธีการซื้อ",
      "title_en":"How to order",
      "icon": "stroke-support-tickets",
      "iconf":"fill-support-tickets",
      "type": "link",
      "active":false
    },
    
    {
      "path": "/cart",
      "title": "ตะกร้า",
      "title_en":"Cart",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active":false
    },
    {
      "path": "/find-order",
      "title": "ค้นหาคำสั่งซื้อ",
      "title_en":"Find order",
      "icon": "stroke-search",
      "iconf":"fill-search",
      "type": "link",
      "active":false
    },
    {
      "path": "/about",
      "title": "เกี่ยวกับเรา",
      "title_en":"About",
      "icon": "stroke-faq",
      "iconf":"fill-faq",
      "type": "link",
      "active":false
    },
    {
      "path": "https://photo.ishootrun.in.th/",
      "title": "ช่างภาพ",
      "title_en":"Photographer",
      "icon": "stroke-user",
      "iconf":"fill-faq",
      "type": "extLink",
      "active":false
    }
  ]
}