<template>
  <head>
    <title>ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ค้นหารูปวิ่ง | ISHOOTRUN.IN.TH</title>
    <meta name="description" content="ภาพหนึ่งภาพมีค่าเท่ากับคำพูดเป็นพันคำ ค้นหารูปวิ่ง ด้วยใบหน้า ค้นง่าย จ่ายสะดวก โหลดรูปต้นฉบับ ความละเอียดสูงได้ทันที ISHOOTRUN.IN.TH" />
    <meta name="keywords" content="ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,ishootrun,ishootrunhub,facesearch" />
    <meta name="author" content="www.ishootrun.in.th | ishootrun LINE ID : @ishootrun.in.th" />
    <meta name="distribution" content="Global">
    <meta name="language" content="en-th">
    <meta property="og:image" content="https://ishootrun.in.th/img/home-banner.f57bc6c1.png" />
  </head>
  <!-- <div class="loader-wrapper" v-if="loading">
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
        <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
      </filter>
    </svg>
  </div> -->
  <router-view ></router-view>
</template>

<script>

export default {
  
  name: 'App',
  data() {
    return {
      loading: true,
    }
  },
  // watch: {
  //   '$route'() {
  //     setTimeout(() => {
  //       this.loading = !this.loading
  //     }, 500)
  //     this.loading = !this.loading
  //   }
  // },
  mounted() {
    this.loading = false;

  }
}
</script>

