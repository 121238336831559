import {createStore} from 'vuex'

import layout from './modules/layout';
import menu from './modules/menu';


import bootsrap from "./modules/bootsrap"
import language from "./modules/language"


export default createStore({
  state:{langIcon: '',langLangauge: '',isActive:false},
  getters:{
    langIcon: (state)=>{ return state.langIcon},
    langLangauge:(state)=>{return state.langLangauge}
  },
  mutations: {
      changeLang (state, payload) {
        //console.log("state:"+JSON.stringify(state))
        //console.log("payload:"+JSON.stringify(payload))
        if(payload.icon === 'flag-icon-th'){
          payload.id = 'th'
        }else{
          payload.id = 'en'
        }
        //console.log("payloadn:"+JSON.stringify(payload))
        localStorage.setItem('currentLanguage', payload.id);
        localStorage.setItem('currentLanguageIcon', payload.icon);
        state.langIcon = payload.icon || 'flag-icon-th'
        state.langLangauge = payload.id || 'th'
      },
      change(state){
        state.isActive = !state.isActive
      }
    },
    actions: {
      setLang ({ commit }, payload) {
        commit('changeLang', payload);  
        
      }
    },
    modules: {

      layout,
     
      menu,
     
  


      language,
      bootsrap
    }
});

