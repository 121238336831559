<template>
  
</template>

<script>

  export default {
    /*
    name: 'Profile',
    methods: {
      logout: function() {
            this.$router.replace('/auth/login');
            localStorage.removeItem('User')
          }
    },
    */
  };
</script>
