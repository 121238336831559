<template>
  <div class="header-wrapper row col-5 m-0"  >
    <Logo />
    <!-- <div class="left-header col-5 p-0">
      
      <swiper-slide class="h-100">
        <div class="d-flex h-100">
          
          <h6 class="mb-0 f-w-400">
            <span class="f-light"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Can find you smile</span>
          
          </h6>
          <img src="@/assets/images/giftools.gif" alt="gif" />
        </div>
      </swiper-slide>
   
    </div> -->
    <div
      class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto"
    >
      <ul class="nav-menus">
        <!-- <li>
          <Mode />
        </li> -->
        <li class="language-nav">
          <Language />
        </li>
        <Cart />
        <Notifications />
        <Profile />
        &nbsp;
        &nbsp;
        &nbsp;
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Bookmark from "../bookmark";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Language from './language';
import Notifications from "./notifications.vue";
import Mode from "./mode";
import Cart from "./cart";
import Profile from "./profile";
import Logo from "./logo.vue";
export default {
  components: {
    Bookmark,Language,
    Notifications,
    Mode,
    Cart,
    Profile,
    Logo,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      bookmark: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {
    
    /*
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },*/
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>
