<template>
  <Breadcrumbs title="วิธีการโหลดภาพ (How to " main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div >
                <div class="media-body">
                  <p><vue-feather type="file-text"></vue-feather></p>
                  <h4>วิธีการโหลดภาพ (How to use)</h4><br>
                  <b>การโหลดผ่านอุปกรณ์ iPhone, iPad</b><br>
                  <b>การโหลดผ่านอุปกรณ์ แอนดรอย</b><br>
                  <b>การโหลดผ่านอุปกรณ์ คอมพิวเตอร์</b>
                  
                  <p>หากติดปัญหาในการใช้งาน สามารถสอบถามข้อมูลเพิ่มเติมได้ที่ Facebook : <a target="_blank" href="https://www.facebook.com/ishootrun.in.th">iSHOOTRUN.IN.TH</a></p>
                    <p style="text-align: right;" >iSHOOTRUN.IN.TH</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>