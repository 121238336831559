<template>
  <li class="cart-nav onhover-dropdown">
    <div class="cart-box">
      <router-link :to="'/cart'" >
      <vue-feather type="shopping-cart"   ></vue-feather>
      <span class="badge rounded-pill badge-success">{{cart_store.cart.length}}</span></router-link>
    </div>
  </li>
</template>

<script setup>

import { useCartStore } from "./../../store/modules/cart";

const cart_store = useCartStore()
</script>
